<template>
    <div
        ref="root"
        v-inview
        class="call-to-action"
        :class="{ 'call-to-action--compact': !image }"
        :data-color-theme="theme"
    >
        <div class="call-to-action__image">
            <BaseImage
                v-if="image"
                class="base-image--cover"
                :src="image.w768"
                :src-tiny="image.w32"
                :srcset="imageSrcSet"
                :width="image.width"
                :height="image.height"
                :title="image.title || undefined"
                :alt="image.alt || undefined"
                :has-focal-point="image.hasFocalPoint"
                :focal-point="image.focalPoint as number[] || undefined"
            />
        </div>

        <div class="call-to-action__content">
            <ClientOnly>
                <Transition
                    name="fade"
                    appear
                >
                    <h3
                        v-if="title"
                        class="call-to-action__title"
                    >
                        {{ title }}
                    </h3>
                </Transition>
            </ClientOnly>

            <ClientOnly>
                <Transition
                    name="fade"
                    appear
                >
                    <div
                        class="call-to-action__description"
                        v-html="text"
                    />
                </Transition>
            </ClientOnly>

            <div v-if="callToAction && callToAction.type">
                <NavLink
                    class="call-to-action__link dn-button button"
                    :type="mapType(callToAction.type)"
                    :url="mapURI(callToAction)"
                    :blank="isExternal(callToAction)"
                    @click="$emit('click')"
                >
                    {{ callToAction.customText || callToAction.text }}
                </NavLink>
            </div>

            <div
                v-if="
                    secondaryCallToAction && secondaryCallToAction.type &&
                        (!!secondaryCallToAction.element || secondaryCallToAction.url)
                "
            >
                <NavLink
                    class="call-to-action__secondary dn-button button button--transparent"
                    :type="mapType(secondaryCallToAction.type)"
                    :url="mapURI(secondaryCallToAction)"
                    :blank="isExternal(secondaryCallToAction)"
                >
                    {{ secondaryCallToAction.customText || secondaryCallToAction.text }}
                </NavLink>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { LinkField_Link } from '~/@types/craft-schema';
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';
import { applyTransform } from '~/utils/transforms';

interface Props {
    title?: string|null;
    theme?: string|null;
    text?: string|null;
    image?: Media_Asset_Extended | null;
    callToAction?: LinkField_Link|null;
    secondaryCallToAction?: LinkField_Link|null;
}

defineEmits(['click']);

const props = defineProps<Props>();

const root = ref<HTMLDivElement|null>(null);
const img = ref<HTMLElement|null>(null);

const { mapType, mapURI, isExternal } = useNavigation();

const imageSrcSet = computed(() => {
    if (props.image) {
        return `
            ${props.image.w320} 320w,
            ${props.image.w656} 656w,
            ${props.image.w1312} w1312w
        `.trim();
    }

    return '';
});

const updateImage = () => {
    if (root.value && img.value) {
        const ctaBounding = root.value.getBoundingClientRect();
        const offsetTop = ctaBounding.top - window.innerHeight - (img.value.offsetHeight * 0.5);

        if (img.value) {
            applyTransform(img.value, '0', -offsetTop, 0.1);
        }
    }
};

onMounted(() => {
    if (!root.value) {
        return;
    }

    img.value = root.value.querySelector('.call-to-action__image .base-image');

    const scrollEl = root.value.closest('.dn-modal') ? root.value.closest('.dn-modal') : window;

    if (img.value) {
        if (scrollEl) {
            scrollEl.addEventListener('scroll', updateImage);
        }

        updateImage();
    }
});
</script>

<style lang="less" src="./CallToActionBlock.less"></style>
